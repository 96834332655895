import React from 'react'
import storyImage from '../assets/images/story.jpg'
function Story () {
  return (
    <div id='story' className='story section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-5 mb-30'>
            <div className='story-img animate-box'>
              <div className='img'>
                {' '}
                <img src={storyImage} className='img-fluid' alt='' />{' '}
              </div>
              <div
                className='story-img-2 story-wedding'
              ></div>
            </div>
          </div>
          <div className='col-md-7 animate-box'>
            <h4 className='oliven-story-subtitle'>Nasza historia</h4>
            {/* <h3 className='oliven-story-title'>Nasza historia</h3> */}
            <br/>
            <p>
            Początki naszej relacji sięgają klasy licealnej w Lublinie. Choć na początku łączyła nas tylko przyjaźń to z czasem nasza relacja uległa zmianie. Pierwsze spotkania sam na sam, choć nie miały etykiety randki, pozwoliły nam dostrzec siebie w zupełnie innym świetle. Wspólnie spędzany czas zarówno w życiu codziennym, jak i w trakcie uwielbianych przez nas podróżach uświadomił nam siłę naszego uczucia, czego efektem były życiowe zmiany. To właśnie z podróżami łączą się najważniejsze momenty naszej wspólnej historii. Maroko stało się symbolem pierwszych poważnych deklaracji, a magiczna Lizbona początkiem kolejnego etapu jakim były oświadczyny.
            <br/><b>5 lutego 2023 roku, Alicja powiedziała tak!</b><br/> 
            Od tego czasu jesteśmy dla siebie największym wsparciem i motywacją do rozwoju. Nasze wspólne plany i marzenia obejmują kolejne podróże, budowę domu oraz założenie rodziny. W ferworze pracy oraz studiów planujemy nasze wymarzone wesele, którego mamy nadzieję będziecie ważną częścią.            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Story
