import React from 'react'
import { GoogleMap, LoadScript, Marker, DirectionsService} from '@react-google-maps/api';
import where1 from '../assets/images/church.jpeg'
import where2 from '../assets/images/hotel.jpeg'
import where3 from '../assets/images/where-3.jpg'

const containerStyle = {
	width: '100%',
	height: '40vh',
};
  
  const church = {
    name: 'Bazylika Mniejsza M.B. Kębelskiej',
    position: {
      lat: 51.297068,
      lng: 22.144393
    }
  };
  
  const hotel = {
    name: 'Focus Hotel Premium Lublin Conference & SPA',
    position: {
      lat: 51.292860,
      lng: 22.453134
    }
  };

  const center = {
    lat: (hotel.position.lat + church.position.lat) / 2,
    lng: (hotel.position.lng + church.position.lng) / 2
    };
    const Where = () => {

      const [directions, setDirections] = React.useState(null);
    
      const directionsCallback = (response) => {
        if (response !== null) {
        if (response.status === 'OK') {
          setDirections(response);
        } else {
          console.error('Directions request failed due to ' + response.status);
        }
        }
      };
    
      return (
        <div id='whenwhere' className='whenwhere section-padding bg-pink'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 mb-30'>
                {' '}
                <span className='oliven-title-meta'>Najważniejsze informacje</span>
                <h2 className='oliven-title'>Gdzie i kiedy?</h2>{' '}
              </div>
            </div>
            <div className='row'>
              <div className='item col-12 col-md-4'>
                <div className='whenwhere-img'>
                  {' '}
                  <img src={where1} alt='' />
                </div>
                <div className='content'>
                  <h5>Ceremonia ślubu</h5>
                  <p>
                    <i className='ti-location-pin'></i> Bazylika Mniejsza Matki Bożej Kębelskiej i parafia pw. św. Wojciecha <br/> ul. Zamkowa 24, 24-160 Wąwolnica
                  </p>
                  <p>
                    <i className='ti-time'></i> <span>Rozpoczęcie mszy świętej przewidziane jest na godzinę 14:00</span>
                  </p>
                </div>
              </div>
              <div className='item col-12 col-md-4'>
                <div className='whenwhere-img'>
                  {' '}
                  <img src={where2} alt='' />
                </div>
                <div className='content'>
                  <h5>Wesele</h5>
                  <p>
                    <i className='ti-location-pin'></i> Focus Hotel Premium Lublin Conference & SPA,
                    Panieńszczyzna 15P, 21-002
                  </p>
                  <p>
                    <i className='ti-time'></i> <span>Uroczyste przywitanie przewidziane jest na godzinę 16:00</span>
                  </p>
                </div>
              </div>
              <div className='item col-12 col-md-4'>
                <div className='whenwhere-img'>
                  {' '}
                  <img src={where3} alt='' />
                </div>
                <div className='content'>
                  <h5>Noclegi</h5>
                  <p>
                    <i className='ti-direction-alt'></i> Focus Hotel Premium Lublin Conference & SPA,
                    Panieńszczyzna 15P, 21-002
                  </p>
                  <p>
                    <i className='ti-time'></i> <span>Zainteresowanych prosimy o kontakt</span>
                  </p>
                </div>
              </div>
              <div className='item col-12 col-md-4'>
                <div className='map-cointaner'>
                  <LoadScript googleMapsApiKey='AIzaSyCqLBml7oxiKkZDXn5g2-jYzfjasPkZBkQ'
                  scriptLoader={{
                    async: true,
                    defer: true}}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={11}
                      options={{
                        draggable: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      <Marker name={church.name} position={church.position} visible={true}/>
                      <Marker name={hotel.name} position={hotel.position} />
    
                      {directions && (
                      <DirectionsService
                        options={{
                        destination: 'Focus Hotel Premium Lublin Conference & SPA Panieńszczyzna 15P, 21-002',
                        origin: 'Bazylika Mniejsza M.B. Kębelskiej',
                        travelMode: 'DRIVING'
                        }}
                        callback={directionsCallback}
                      />
                      )}
                    </GoogleMap>
                  </LoadScript>
                </div>
                <div className='content'>
                  <h5>Mapa dojazdu</h5>
                  {/* <p>
                    <i className='ti-direction-alt'></i> Focus Hotel Premium Lublin Conference & SPA,
                    Panieńszczyzna 15P, 21-002
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
}

export default Where
