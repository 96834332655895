import React from 'react'

function Bridegroom () {
  return (
    <div id='couple' className='bridegroom clear section-padding bg-pink'>
      <div className='container'>
        <div className='row mb-60'>
          <div className='col-md-6'>
            <div
              className='item toright mb-30 animate-box'
              data-animate-effect='fadeInLeft'
            >
              <div className='info valign'>
                <div className='full-width'>
                  <h6>
                    Alicja Masajada <i className='ti-heart'></i>
                  </h6>{' '}
                  <span>Panna Młoda</span>
                  <p>
                    Alicja na codzień łączy studia magisterskie na kierunki Big Data na SGH w Warszawie z pracą zawodową analityka biznesowego w firmie Deloitte. 
                  </p>
                  <div className='social'>
                    <div className='full-width'>
                      <a href='#0' className='icon'>
                        {' '}
                        <i className='ti-facebook'></i>{' '}
                      </a>
                      <a href='#0' className='icon'>
                        {' '}
                        <i className='ti-twitter'></i>{' '}
                      </a>
                      <a href='#0' className='icon'>
                        {' '}
                        <i className='ti-instagram'></i>{' '}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='item mb-30 animate-box'
              data-animate-effect='fadeInRight'
            >
              <div className='info valign'>
                <div className='full-width'>
                  <h6>
                    Wojciech Dołęga <i className='ti-heart'></i>
                  </h6>{' '}
                  <span>Pan Młody</span>
                  <p>
                    Wojciech na codzień łączy studia magisterskie na kierunku Informatyka Stosowana na Politechnice Warszawskiej z pracą zawodową programisty w porównywarce ubezpieczeń Mubi.pl. 
                  </p>
                  <div className='social'>
                    <div className='full-width'>
                      <a href='#0' className='icon'>
                        {' '}
                        <i className='ti-facebook'></i>{' '}
                      </a>
                      <a href='#0' className='icon'>
                        {' '}
                        <i className='ti-twitter'></i>{' '}
                      </a>
                      <a href='#0' className='icon'>
                        {' '}
                        <i className='ti-instagram'></i>{' '}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div
            className='col-md-12 text-center animate-box'
            data-animate-effect='fadeInUp'
          >
            <h3 className='oliven-couple-title'>Bierzemy ślub!</h3>
            <h4 className='oliven-couple-subtitle'>
              
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bridegroom
