import React from 'react'

function RSVP () {
  return (
    <div
      id='rsvp'
      className='section-padding bg-img bg-fixed'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-6 bg-white p-40'>
            {' '}
            <span className='oliven-title-meta text-center'>R.S.V.P</span>
            <h2 className='oliven-title text-center'>Czy mozemy liczyć na Twoją obecność?</h2>
            <p>Zachęcamy do udzielenia informacji za pośrednictwem ankiety Google lub do bezspośredniego kontaktu telefoniczego. 
              <br/>Alicja - 667 452 520 | Wojciech - 691 527 350</p>
            <br />
            <a href='https://forms.gle/pwdCgNvgwYLo1vbj6' target="_blank" rel="noopener noreferrer">
              <button className='btn buttono'>Ankieta</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RSVP
