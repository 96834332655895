import React from 'react'

function Organization () {
  return (
    <div id='organization' className='organization section-padding bg-pink'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 mb-30'>
            <span className='oliven-title-meta'>Informacje organizacyjne</span>
            <p>Nasz długo wyczekiwany ślub odbędzie się w rodzinnej parafii Alicji w Wąwolnicy 17 sierpnia 2024 roku o godzinie 14:00. 
              Po ceremonii zaślubin zapraszamy na przyjęcie weselne, które odbędzie się w Focus Hotel Premium Lublin Conference & SPA. 
              Rozpoczęcie przyjęcia przewidziane jest na godzinę 16:00, po którym zapraszamy Was na uroczysty obiad. 
              Po pierwszym tańcu zapraszamy Was serdecznie w jak najliczniejszym gronie na parkiet. 
              Pragniemy zadbać o komfort naszych najmłodszych gości, których zapraszamy do dedykowanej strefy zabaw. 
              <b> Ze względu na charakter naszego wesela, na stołach zabraknie butelek wódki, a wszystkich gości zapraszamy serdecznie do skorzystania z usług barmańskich Let's Drink. Zachęcamy do sprawdzenia starannie skomponowanej karty koktajli, zarówno w wersji alkoholowej jak i bezalkoholowej (Pan Młody poleca!). </b>
              Mamy nadzieję, że każdy znajdzie coś dla siebie. Zachęcamy do skorzystania z fotobudki, która będzie dostępna od początku przyjęcia, aż do godziny 22:00.</p>
            <h2 className='oliven-title'>Harmonogram</h2>
          </div>
        </div>
        <div className='row bord-box bg-img' data-background='images/slider.jpg'>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>01</h2>
            <h6 className='title'>Ceremonia zaślubin</h6>
            <p>
              Ceremonia zaślubin odbędzie się 17.08.2024 r. o godz. 14:00  w parafii pw. św. Wojciecha w Wąwolnicy 
            </p>
          </div>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>02</h2>
            <h6 className='title'>Uroczysty obiad</h6>
            <p>
            </p>
          </div>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>03</h2>
            <h6 className='title'>Wesele</h6>
            <p>

            </p>
          </div>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>04</h2>
            <h6 className='title'>Tort</h6>
            <p>

            </p>
          </div>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>05</h2>
            <h6 className='title'>Zimne ognie</h6>
            <p>
              
            </p>
          </div>
          <div className='col-md-3 item-box'>
            <h2 className='custom-font numb'>06</h2>
            <h6 className='title'>Oczepiny</h6>
            <p>
              
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Organization
